body {
  background-color: black;
}
.about {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-content img {
  height: auto;
  width: 600px;
  margin-top: 1rem;
  max-width: 100%;
  border-radius: 5px;
}
.about-text {
  width: 550px;
  max-width: 100%;
  padding: 0 10px;
}
.about-content {
  width: 1280px;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.heading-small {
  color: white;
  margin-bottom: 14px;
}

.about-button {
  background-color: purple;
  padding: 10px;
  border-radius: 10px;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 1.5px;
}
.about-button:hover {
  transform: scale(1.2);
  background-color: transparent;
  border: 2px solid #111111;
  color: purple;
  cursor: pointer;
}
