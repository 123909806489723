.footer {
  background-color: black;
  color: white;
  padding: 10px;
}

.footer-container {
  width: 100%;
  display: grid;
  grid-template-columns: 40% 30% 30%;
}

.footer-container > div {
  margin: 0 10px;
}
.footer-logo {
  width: 120px;
}
.footer-container1,
.footer-container3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-container2 > .footer-link-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-container2 > h3,
.footer-container3 > h3 {
  margin-bottom: 10px;
}

.footer-container11 {
  display: flex;
  align-items: center;
}
.footer-title {
  font-size: 23px;
}
.about-us-text {
  text-align: justify;
}

.footer-row-align {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer-link-list {
  list-style: none;
}

.list-align {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-items {
  text-decoration: none;
  list-style: none;
  margin: 5px;
}

.footer-links {
  text-decoration: none;
  color: white;
  transition: all 0.1s ease-in-out;
}

.footer-links:hover {
  color: rgba(148, 66, 224, 0.801);
}

.icons {
  margin: 0 10px 0 0;
}

.horizontal-line {
  width: 99%;
  border: 1px solid rgba(128, 60, 184, 0.572);
  margin: auto;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-top: 5px;
}

.social-media-icons {
  display: flex;
  align-items: center;
}

.social-media {
  margin: 0 0 0 7px;
}

@media only screen and (max-width: 850px) {
  .footer-container {
    grid-template-columns: 100%;
    margin-bottom: 20px;
  }
  .footer-container1,
  .footer-container2,
  .footer-container3 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-container2 > h3,
  .footer-container3 > h3 {
    margin-bottom: 15px;
  }
}
