.dynamic-text-shadow {
  /*  larger font size, more shadow distance  */
  text-shadow: 0.1em 0.1em 0.2rem #8c20be;
}

.dynamic-celestra-shadow {
  /*  larger font size, more shadow distance  */
  text-shadow: 0.12em 0.12em 0 #8c20be;
}

.dynamic-celestra-white-shadow {
  text-shadow: -4px 8px 0 white;
}

.dynamic-celestra-white-shadow-mobile {
  text-shadow: -4px 4px 0 white;
}

#hero-container {
  background: linear-gradient(#0000008e, #0000008e) center/cover no-repeat,
    url("../../assets/bg2.jpg") center/cover no-repeat;
  height: 100%;
}

#dropDown-container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/bg4.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  background-position: center;
}
