@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: AstranovaHeroFont;
  src: url("./assets/Glitchinside.otf");
}
.AstranovaHeroFont {
  font-family: AstranovaHeroFont;
}

::-moz-selection {
  color: pruple;
  background: white;
}

::selection {
  color: pruple;
  background: white;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Raleway", sans-serif;
    overflow-x: hidden;
  }
  body {
    overflow-x: hidden;
  }
}

@keyframes pulse {
  50% {
    opacity: 0.2;
  }
}
.animate-pulse {
  animation: pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
